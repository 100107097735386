import {
  createMemo,
  Show,
  For,
  createSignal,
  createEffect,
} from "solid-js";
import {
  type RouteDefinition,
  type RouteSectionProps,
  createAsync,
  useNavigate,
  A,
} from "@solidjs/router";
import { useAccountProfile } from "~/services/account";
import { useSiteContext, useSessionContext } from "~/utils/contexts";
import Body from "~/components/Body";
import Button from "~/components/Button";
import { Icon } from "solid-heroicons";
import { chevronRight, arrowRightOnRectangle } from "solid-heroicons/outline";
import { Presence } from "solid-motionone";
import {
  accountNav,
  shoppingNav,
  adminNav,
  filterByPermissions,
} from "~/utils/account";

export const route = {
  preload: () =>
    useAccountProfile(
      { bps: true, communications: true },
      { cleanShipTos: true }
    ),
} satisfies RouteDefinition;

export default function AccountLayout(props: RouteSectionProps) {
  const { breakpoints } = useSiteContext();
  const { clearSession, session } = useSessionContext();
  const [showNavigation, setShowNavigation] = createSignal(true);
  const navigate = useNavigate();

  const details = createAsync(() =>
    useAccountProfile(
      { bps: true, communications: true },
      { cleanShipTos: true }
    )
  );

  const options = createMemo(() => {
    const navItems = [
      { label: "Admin", nav: adminNav },
      { label: "Account", nav: accountNav },
      { label: "Shopping", nav: shoppingNav },
    ];

    if (!details() || !details()?.Permissions) {
      // return no options for each category
      return navItems.map((item) => ({ label: item.label, options: [] }));
    }

    const permissions = session()?.permissions ?? [];

    return navItems.map((item) => ({
      label: item.label,
      options: filterByPermissions(item.nav, permissions),
    }));
  });
  
  createEffect(() => {
    if (!breakpoints.md) {
      setShowNavigation(false);
    }
  });

  return (
    <Show when={details()}>
      <Body>
        <div class="boundary-outer">
          <header class="w-full flex justify-between items-start sm:items-center pt-8">
            <p class="text-2xl sm:text-4xl font-bold">
              Hello, {details()?.FirstName}!
            </p>
            <div class="flex flex-col gap-2 items-stretch">
              <Button
                style="outline"
                onClick={() => {
                  clearSession();
                  navigate("/?signIn=true");
                }}
              >
                <span class="text-xs sm:text-sm font-medium px-3">
                  Not you? Switch Accounts
                </span>
              </Button>
              <Button
                class="w-full"
                style="outline"
                onClick={() => {
                  setShowNavigation(!showNavigation());
                }}
              >
                <span class="text-xs sm:text-sm font-medium">
                  {showNavigation() ? "Hide Menu" : "Show Menu"}
                </span>
              </Button>
            </div>
          </header>

          <div class="w-full flex gap-0 items-start mt-6 ">
            <div
              class="shrink-0 w-full md:w-[275px] bg-white flex flex-col gap-4 fixed inset-0 max-md:z-[25] max-md:px-10  md:sticky top-9 max-h-screen  overflow-y-auto scrollbar-custom transition-all duration-700 pr-0 mr-0 md:pr-4 md:mr-4 md:border-r "
              classList={{
                "-translate-x-[350px] !w-[0px] !whitespace-nowrap !pr-0 !mr-0":
                  !showNavigation(),
              }}
            >
              <button
                class="md:hidden sticky top-0 backdrop-blur-md -mx-10 bg-roma-grey flex items-center justify-center z-[26] py-4"
                onClick={() => {
                  setShowNavigation(false);
                }}
              >
                <div class="flex items-center">
                  <span>Hide Menu</span>
                  <Icon path={chevronRight} class="w-5 pb-1 ml-3" />
                </div>
              </button>
              <div class="flex flex-col items-start gap-1 pr-2">
                <p class="text-xl font-medium">
                  {details()?.Company.FirstName}
                </p>
                <p>Customer #{details()?.CustomerNumber}</p>
                <Show when={details()?.Premiere}>
                  <div class="bg-roma-blue rounded-sm text-white text-center text-sm font-bold px-1">
                    <p>Premier Partner</p>
                  </div>
                </Show>
              </div>
              <nav class="text-roma-dark-grey flex flex-col gap-4 md:pb-10">
                <For each={options()}>
                  {(category) => (
                    <Show when={category.options.length > 0}>
                      <div>
                        <h3 class="text-sm uppercase pb-4 font-medium">
                          {category.label}
                        </h3>
                        <ul class="flex flex-col">
                          <For each={category.options}>
                            {(item) => (
                              <li class="flex w-full gap-2 font-medium group transition-[color] child:duration-300">
                                <A
                                      href={(item as { href: string }).href}
                                      activeClass="text-roma-blue"
                                      class="flex items-center w-full py-1 gap-2 group-hover:text-roma-blue"
                                    >
                                      <Icon
                                        path={item.icon}
                                        class="w-5 group-hover:text-roma-blue shrink-0"
                                      />
                                      {item.label}
                                      <Show when={item.new}>
                                        <div class="flex bg-roma-blue justify-center items-center text-xs rounded-xl text-white px-2">
                                          <span class="mb-0.5">New</span>
                                        </div>
                                      </Show>
                                    </A>
                              </li>
                            )}
                          </For>
                        </ul>
                      </div>
                    </Show>
                  )}
                </For>
              </nav>
            </div>
            <main
              class="w-full grow shrink-0 md:shrink "
              classList={{ "pl-8": !!showNavigation() }}
            >
              {/* <Presence exitBeforeEnter>{props.children}</Presence> */}
              {props.children}
            </main>
          </div>
        </div>
      </Body>
    </Show>
  );
}
